<template>
  <div class="app-container flex flex-v">
    <div class="searchBar bg-white">
      <el-row>
        <el-col :span="8" :offset="1">
          <div class="fl title">{{ merchantsType ? '供应商名称' :'客户名称' }}</div>
          <div class="fl" style="width: 60%">
            <el-input clearable v-model="name" size="mini" :placeholder="merchantsType ? '请输入供应商名称' :'请输入客户名称'"/>
          </div>
        </el-col>
        <el-col :span="8" :offset="1">
          <div class="fl title">{{ '纳税人识别码' }}</div>
          <div class="fl" style="width: 60%">
            <el-input clearable v-model="identificationCode" size="mini" :placeholder="'纳税人识别码'"/>
          </div>
        </el-col>
        <el-col :span="4" :offset="2" style="float: right">
          <el-button size="mini" class="btnBackColor" type="primary" @click="search({currentPage: 1})" icon="el-icon-search">查 询</el-button>
          <el-button size="mini" class="btnBackColor" type="primary" plain @click="goEditPage1" icon="el-icon-plus">创 建</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="bg-white main-content-wrapper margin-top-10 flex flex-v flex-1">
      <el-table
          header-cell-class-name="avue-crud"
          height="666px"
          class="flex-1"
          stripe
          ref="mytable"
          :data="tableData"
          style="width: 100%;"
          size="mini"
      >
        <el-table-column :label="merchantsType ? '供应商编码' :'客户编码'" align="center" min-width="150">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="goEditPage(scope.row)" >{{ scope.row.merchantsCode }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
            :label="merchantsType ? '供应商名称' :'客户名称'"
            align="center"
            min-width="180"
        >
          <template slot-scope="scope">
            <p v-show="!scope.row.edit">{{ scope.row.merchantsName }}</p>
            <el-input
                v-show="scope.row.edit"
                v-model="scope.row.merchantsName"
                size="mini"
                class="formInput"
                :placeholder="merchantsType ? '供应商名称' :'客户名称'"
            />
          </template>
        </el-table-column>
        <el-table-column
            label="纳税人识别号"
            align="center"
            min-width="200"
            show-overflow-tooltip
        >
          <template slot-scope="scope">
            <p v-show="!scope.row.edit">{{ scope.row.identificationCode }}</p>
            <el-input
                v-show="scope.row.edit"
                v-model="scope.row.identificationCode"
                size="mini"
                class="formInput"
                placeholder="纳税人识别号"
            />
          </template>
        </el-table-column>
        <el-table-column
            label="账期"
            min-width="80"
            align="center"
        >
          <template slot-scope="scope">
            <p v-show="!scope.row.edit">{{ scope.row.zhangqi + '天' }}</p>
            <el-input
                v-show="scope.row.edit"
                v-model="scope.row.zhangqi"
                size="mini"
                class="formInput"
                placeholder="账期"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="140"  fixed="right">
          <template slot-scope="scope">
            <div>
              <el-button
                  v-if="!scope.row.edit"
                  size="mini"
                  type="text"
                  @click="goEditPage(scope.row)"
              >编辑
              </el-button>
              <el-button
                  size="mini"
                  type="text"
                  @click="handleDelete(scope.$index, scope.row)"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    分页    -->
    <Pagination
        :page.sync="tablePage.currentPage"
        :total="tablePage.total"
        :limit.sync="tablePage.pageSize"
        @pagination="paginationChange"
    />
    <el-dialog title="错误详情" :visible.sync="dialogTableVisible" width="550px">
      <div style="margin-bottom:15px">{{ this.errInfo }}</div>
      <el-table :data="errorList" size="mini">
        <el-table-column label="错误信息" width="500" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import service from '@/api/company-foriegn'
import Pagination from '@/components/Pagination/index'
// import download from 'downloadjs'

export default {
  // components: { Template },
  components: {Pagination},
  data() {
    return {
      dialogTableVisible: false,
      errInfo: '',
      fileList: [],
      name: '',
      identificationCode: '',
      msg: '',
      f: '',
      errorList: [],
      tableData: [],
      intparam: [],
      status: '',
      statusList: [
        {
          code: 0,
          label: '启用'
        },
        {
          code: 1,
          label: '禁用'
        }
      ],
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 10
      },
      merchantsType: null
    }
  },
  computed:{
    editButton(){
      let edit_button = false;
      if(this.$store.state.user.menuListAll){
        let obj = this.$store.state.user.menuListAll.find(f => f.code === (this.$route.name === 'customerMerchantsInfo-user'?"base-customer-user":'hqt-customer'));
        edit_button =  obj && obj.children.length>0 &&
                       obj.children.find(item => item.code === (this.$route.name === 'customerMerchantsInfo-user' ? 'user_edit':'custom_edit'));
      }
      return edit_button;
    },
  },
  mounted() {
    this.merchantsType= this.$route.name === 'customerMerchantsInfo-user' ? 0 : 1, // 0 用户  1 供应商
    this.search({currentPage: 1});
  },

  methods: {
    search({ currentPage }) {
      let data = {
        merchantsName: this.name,
        identificationCode: this.identificationCode,
        status: this.status,
        currentPage: currentPage ,
        pageSize: this.tablePage.pageSize,
        merchantsType: this.$route.name === 'customerMerchantsInfo-user' ? 0 : 1,//
      }
      service.getForiegnList(data).then(data => {
        if (data.data) {
          data.data.data.forEach(item => {
            item.edit = false
          })
          this.tableData = data.data.data
          this.tablePage.total = data.data.count
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      })
    },
    goEditPage(row) {
      if(this.merchantsType === 0){
          this.$router.push({
            path: 'customerMerchantsInfoEdit-userInfo?number=' + row.id
          })
      }else {
        this.$router.push({
          path: 'customerMerchantsInfoEdit-businessInfo?number=' + row.id
        })
      }
    },
    goEditPage1() {
      if(this.merchantsType === 0){
        this.$router.push({name: 'customerMerchantsInfoEdit-userInfo2'})
      }else {
        this.$router.push({name: 'customerMerchantsInfoEdit-businessInfo2'})
      }

    },
    handleEdit(index, row) {
      row.edit = true
    },
    handleSaveRow(index, row) {
      service.saveForiegn(row).then(data => {
        this.search()
      })
    },
    handleDelete(index, row) {
      this.$confirm('确定要删除此项？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        service.removeForiegn({id: row.id}).then(data => {
          if (data.message === '成功') {
            this.$message.success('删除成功！')
            this.search()
          }
        })
      })
    },
    startUseing(row) {
      this.$confirm('确定要启用此项？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        row.status = 0;
        service.saveForiegn(row).then(data => {
          this.$message.success('启用成功！')
          this.search()
        })
      })
    },
    stopUseing(row) {
      this.$confirm('确定要禁用此项？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        row.status = 1;
        service.saveForiegn(row).then(data => {
          this.$message.success('禁用成功！')
          this.search()
        })
      })
    },
    paginationChange({page = 1, limit = 10}) {
      this.tablePage.currentPage = page
      this.tablePage.pageSize = limit
      this.search({currentPage: page})
    },
    //下载模板
    downModel() {
      // exportTemplate().then(resp => {
      //   download((new Blob([resp])), '客商管理模板.xlsx');
      // })
    },
    handleExceed(files, fileList) {

    },
    onSuccess(response, file, fileList) {
      if (response.data && response.data.errorList.length > 0) {
        this.errorList = response.data.errorList;
        this.dialogTableVisible = true;
        this.errInfo = response.data.result;
      } else if (response.data && response.data.result) {
        this.$message.success(response.data.result)
      } else {
        this.$message.error(response.message)
      }
      this.fileList = [];
      this.search();
    }

  }
}
</script>

<style lang="scss" scoped>
p {
  font-size: 12px;
}
.app-container {
  background-color: #f6f6f6;
  height: 100%;
}

.fl {
  float: left
}

.fr {
  float: right
}

.clear {
  clear: both
}

.searchBar {
  padding: 30px;

  .title {
    line-height: 32px;
    margin-right: 10px;
    font-size: 12px;
  }
}

.btnBox {
  padding: 10px 20px;
  background: #eee;
  margin-left: -20px;
  margin-right: -20px;
}

.elTable .el-table__header tr
> > > .el-table__header th {
  padding: 15px;
  height: 30px !important;
  background-color: #f6f6f6;
  font-weight: bold;
}

> > > .el-table__header-wrapper .el-checkbox {
  right: 4px;
}

</style>
